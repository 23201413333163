<template>
  <v-row>
    <v-col cols="12">
      <span
        style="white-space: pre-wrap"
      >
        {{ event.event_details.description }}
      </span>
    </v-col>
    <v-col
      v-for="x in fopFields"
      :key="x._id"
      cols="auto"
      class="py-0"
    >
      <v-card flat>
        <v-card-title>{{ x.name }}</v-card-title>
        <v-card-subtitle
          v-if="x.field_type == 'date'"
          class="pb-0"
        >
          <span class="subtitle-1">
            {{ x.value | prettyDate }}
          </span>
        </v-card-subtitle>
        <v-card-subtitle
          v-else-if="x.field_type == 'locations'"
          class="pb-0"
        >
          <v-chip
            v-for="(y, i) in x.value"
            :key="i"
          >
            {{ y }}
          </v-chip>
        </v-card-subtitle>
        <v-card-subtitle
          v-else-if="x.field_type == 'number'"
          class="pb-0"
        >
          <span class="text-h6 font-weight-regular">
            {{ x.value }}
          </span>
        </v-card-subtitle>
        <v-card-subtitle
          v-else-if="x.field_type == 'textarea'"
          class="pb-0"
        >
          <span
            style="white-space: pre-wrap"
            class="body-2 d-block"
          >
            {{ x.value }}
          </span>
        </v-card-subtitle>
        <v-card-subtitle
          v-else-if="x.field_type == 'text'"
          class="pb-0"
        >
          <span class="body-2 text-wrap">
            {{ x.value }}
          </span>
        </v-card-subtitle>
        <v-card-subtitle
          v-else-if="x.field_type == 'email'"
          class="pb-0"
        >
          <span
            class="body-2 text-wrap"
          >
            <a :href="'mailto:' + x.value">{{ x.value }}</a>
          </span>
        </v-card-subtitle>
        <v-card-subtitle
          v-else
          class="pb-0"
        >
          {{ x.field_type }} ->
          {{ x.value }}
        </v-card-subtitle>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import utils from "@/utils";

export default {
  props: {
    event: Object,
  },
  data() {
    return {};
  },
  computed: {
    fopFields() {
      // Map fields from "fop.fopfields" to the "fop.data" values
      const fopTypeFields = utils.mapListToDict(
        this.event.fop.fopfields || [],
        "_id",
        (x) => {
          return {
            name: x.name,
            field_type: x.field_type,
          };
        }
      );
      // Default fields
      let fopFields = [
        {
          name: "Date de début",
          field_type: "date",
          value: this.event.event_details.begin_date,
        },
        {
          name: "Date de fin",
          field_type: "date",
          value: this.event.event_details.end_date,
        },
      ];
      if (this.event.event_details.locations.length) {
        fopFields.push({
          name: "Lieux",
          field_type: "locations",
          value: this.event.event_details.locations.map((x) => x.name),
        });
      }
      // Custom fields
      fopFields.push(
        ...(this.event.fop.data || []).map((x) => {
          return {
            ...fopTypeFields[x.field_id],
            value: x.value,
          };
        })
      );
      return fopFields;
    },
  },
};
</script>